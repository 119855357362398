import Image from '../Image/Image';

import styles from './LinkListSlim.module.scss';

const LinkListSlim = ({ title = '', items = [] }) => {
    return (
        <div className={styles['LinkListSlim']}>
            <div className={styles['LinkListSlim__Container']}>
                <h2 className={styles['LinkListSlim__Title']}>{title}</h2>
                <ul className={styles['LinkListSlim__List']}>
                    {items.map((item, index) => (
                        <ListItem key={index} {...item} />
                    ))}
                </ul>
            </div>
        </div>
    );
};

const ListItem = ({ link = {}, image = {} }) => {
    return (
        <li className={styles['LinkListSlim__Item']}>
            <a className={styles['LinkListSlim__Link']} href={link.href}>
                <span className={'sr-only'}>{link.title}</span>
            </a>
            <figure className={styles['LinkListSlim__Image']}>
                <Image {...{ image }} />
            </figure>
            <span className={styles['LinkListSlim__LinkTitle']} aria-hidden>
                {link.title}
            </span>
        </li>
    );
};

LinkListSlim.propTypes = {};

export default LinkListSlim;
